import images from './images';

const combo = [
  {
    title: 'Delight Platter',
    price: '£12.00',
    tags: '3 Wings, Mac & Cheese, Curly Fries, Corn Or Waffles',
    allergy: 'Contains: Dairy'
  },
  {
    title: 'Deluxe Platter',
    price: '£17.00',
    tags: '5 Wings, Mac & Cheese, Prawns, Curly Fries, Coleslaw, Corn & Waffles',
    allergy: 'Contains: Shellfish, Dairy'
  },
  {
    title: 'Burger Platter',
    price: '£15.00',
    tags: 'Jerk/BBQ Chicken Burger, 3 Wings, Mac & Cheese, Fries',
    allergy: 'Contains: Gluten, Dairy'
  },
  {
    title: 'Premium Platter',
    price: '£19.00',
    tags: 'Salmon, Mac & Cheese, Prawns, Curly Fries, Corn - Sweet Chili Sauce',
    allergy: 'Contains: Fish, Shellfish, Dairy'
  },
  {
    title: 'Salmon With Mac & Cheese',
    price: '£12.00',
    tags: 'Topped with Teriyaki sauce',
    allergy: 'Contains: Fish, Dairy'
  },
  {
    title: 'The Soul Burger Meal',
    price: '£9.00',
    tags: 'Jerk Or BBQ Chicken Burger with fries and can drink',
    allergy: 'Contains: Gluten, Dairy'
  },
  {
    title: 'The Soul Burger',
    price: '£6.00',
    tags: 'Jerk Or BBQ Chicken Burger',
    allergy: 'Contains: Gluten'
  },
  {
    title: 'Loaded Fries',
    price: '£7.00',
    tags: 'Crispy Chicken Over French Fries Topped With Cheese & House Sauce',
    allergy: 'Contains: Gluten, Dairy'
  }
];

const sides = [
  {
    title: '6 Grilled Wings',
    price: '£5.00',
    tags: 'BBQ Or Jerk',
  },
  {
    title: 'Mac & Cheese',
    price: '£5.00',
    tags: 'Loaded +£2',
    allergy: 'Contains: Dairy'
  },
  {
    title: 'Prawns',
    price: '£5.00',
    tags: 'Battered w/ Sweet Chilli Sauce',
    allergy: 'Contains: Shellfish'
  },
  {
    title: 'Salmon',
    price: '£7.00',
    tags: 'Topped With Teriyaki Sauce',
    allergy: 'Contains: Fish'
  },
  {
    title: '3 Grilled Wings & Chips',
    price: '£5.00',
    tags: 'BBQ Or Jerk',
  },
  {
    title: 'Curly Fries',
    price: '£3.00',
    tags: '',
  },
  {
    title: 'Regular Fries',
    price: '£2.00',
    tags: '',
  }
];

const drinks = [
  {
    title: 'Mango Lassi Punch',
    price: '£4.00',
    tags: '',
  },
  {
    title: 'Bigga Bottle',
    price: '£2.00',
    tags: 'Pineapple, Fruit Punch, Grape',
  },
  {
    title: 'J20, Tropical Vibes',
    price: '£2.00',
    tags: '',
  },
  {
    title: 'Can Drinks',
    price: '£1.00',
    tags: 'Rio, Lemonade, Ginger Beer',
  },
  {
    title: 'Water',
    price: '£1.00',
    tags: '',
  }
];

const desserts = [
  {
    title: 'Milk Cake',
    price: '£6.00',
    tags: 'Oreo, Biscoff',
    allergy: 'Contains: Dairy, Wheat, Flour, Eggs'
  },
  {
    title: 'Apple Crumble/Mixed Berry Crumble',
    price: '£5.00',
    tags: '',
    allergy: 'Contains: Wheat, Dairy'
  },
  {
    title: 'Kinder Bueno Cake',
    price: '£5.00',
    tags: '',
    allergy: 'Contains: Dairy, Wheat, Nuts'
  }
];


const sauces = [
  {
    title: 'Sauces',
    price: '£0.50',
    tags: 'Algerian | BBQ | Jerk',
  },
];


export default { combo, sides, desserts, drinks, sauces };
